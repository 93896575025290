import React from "react";
import { graphql, Link } from "gatsby";
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import SimplePortableText from "@components/sanity/simplePortableText";
import { useNavQuery } from "@querys/useNavQuery";
import { SanityImage } from "@components/sanity/sanityImage";
import { Application } from "@components/forms/application";

import Back from "@svg/back.svg";

const JobListing = ({ data: { page } }) => {
  const { title, description, detailsOne, detailsTwo, locationRef, seo } =
    page || {};

  const { reversedLogo } = useNavQuery();

  return (
    <Layout hideNav={true}>
      <PageMeta {...seo} />
      <div className="pt-[170px] lg:pt-[300px] -mb-[64px] lg:pb-[350px] ">
        <a
          href="https://www.cosgroves.com/"
          target="_blank"
          className="absolute top-6 lg:top-14 left-gutter md:left-[50px] xl:left-[100px]"
        >
          <SanityImage
            className="w-[144px] lg:w-[266px] h-auto"
            image={reversedLogo}
          />
        </a>
        <Link to="/opportunities" className="flex px-gutter mb-16">
          <Back className="self-center mr-2" />
          <h1 className="blockB5 text-[#828282]">Back to Opportunities</h1>
        </Link>

        <div className=" lg:grid grid-cols-14 ">
          <div className="col-start-2 col-end-9 px-gutter lg:px-0">
            <h1 className="richH1 text-orange">{title}</h1>
            <p className="richB3 text-black">
              {locationRef?.map((name, i) => (
                <span>
                  <span>{i > 0 && " or "}</span>
                  {name?.locationName}
                </span>
              ))}
            </p>
            <div className="pt-10">
              <SimplePortableText text={description} />
            </div>
            <div className="pt-10">
              <SimplePortableText text={detailsOne} />
            </div>
            <div className="pt-10">
              <SimplePortableText text={detailsTwo} />
            </div>
          </div>
          <div className="col-start-10 col-end-15 pt-10 lg:pt-0 lg:sticky lg:top-0 lg:self-start">
            <Application formName="application" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JobListing;

export const pageQuery = graphql`
  query standardJobListingQuery($slug: String!) {
    page: sanityJobListing(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      detailsOne: _rawDetailsOne(resolveReferences: { maxDepth: 10 })
      detailsTwo: _rawDetailsTwo(resolveReferences: { maxDepth: 10 })
      locationRef {
        locationName
      }
    }
  }
`;
