import React from "react";
import { useLocation } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { PortableTextBlock } from '../sanity/portableTextBlock';

import Upload from "@svg/upload.svg";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const CareerForm = ({
  className,
  formName,
  submitted,
  setSubmitted,
}) => {
  const { pathname } = useLocation();
  const [message, setMessage] = React.useState(null);

  const itemWrapperClasses = "relative col-span-1";

  const inputClasses =
    "block blockB5 rounded shadow-none h-full w-full my-2 border-none p-3 outline-removee placeholder-opacity-50 input-career px-7 lg:px-0";
  const inputClassesLetter =
    "block blockB5 rounded shadow-none w-full my-2 border-none p-3 outline-removee placeholder-opacity-50 input-letter px-7 lg:px-0";

  const errorClasses = "tracking-wide text-sm my-2 text-left text-white";

  //   const onSubmitFile = (values) => {
  //     debugger;
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("form");
    // let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new FormData(e.target),
    })
      .then(() => console.log("Form successfully submitted"))
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <>
      {!submitted && (
        <form
          id="form"
          name={formName}
          enctype="multipart/form-data"
          data-netlify="true"
          className="mb-16"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input type="hidden" name="form-name" value={formName} />
          <p className="text-blue blockB6">Name*</p>
          <p className="text-blue">
            <label>
              <input
                name="name"
                type="text"
                className={inputClasses}
                placeholder="Your name"
              />
            </label>
          </p>
          <p className="text-blue mt-7 blockB6">Phone*</p>
          <p className="text-blue">
            <label>
              <input
                name="phone"
                type="tel"
                className={inputClasses}
                placeholder="Your phone"
              />
            </label>
          </p>
          <p className="text-blue mt-7 blockB6">Email*</p>
          <p className="text-blue">
            <label>
              <input
                name="email"
                type="email"
                className={inputClasses}
                placeholder="Your email"
              />
            </label>
          </p>
          <p className="text-blue mt-7 blockB6">Cover Letter*</p>
          <p className="text-blue">
            <label>
              <textarea
                name="message"
                type="tel"
                className={inputClassesLetter}
                placeholder="Cover letter"
                rows="5"
              ></textarea>
            </label>
          </p>
          <div className="flex flex-col  justify-between mt-10 pb-16 items-center ">
            <div className="lg:flex flex-col  self-start w-full">
              <span className="block text-blue  w-28 mb-2 blockB6">Resume</span>
              <label
                for="file"
                className="block  text-blue text-opacity-80 hover:text-opacity-100 py-3 px-7 rounded-md  text-sm   relative  "
              >
                <input
                  name="file"
                  type="file"
                  className="opacity-0 w-full h-full"
                  onChange={(e) => setMessage(e.target.files[0].name)}
                />

                <div className="input-career border-none absolute top-0 left-0  pt-[14px] h-full text-black text-opacity-50  mr-4  px-7 lg:px-0  pointer-events-none ">
                  Upload your resume
                </div>

                <Upload className="absolute top-4 right-8 z-50" />
              </label>
            </div>
            <button
              className="button-orange w-full md:w-fit md:!px-24 mt-10  self-start"
              type="submit"
            >
              Apply Now
            </button>
            {message && (
              <div className="text-blue pt-10">Selected file: {message}</div>
            )}
          </div>
        </form>
      )}
    </>
  );
};
