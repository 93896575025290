import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CareerForm } from "./careerForm";

export const Application = ({ formName }) => {
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {!submitted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="bg-orange-light px-gutter lg:px-0 pt-[60px] h-full  "
          >
            <h1 className="text-orange richB2  mb-10 px-gutter lg:px-[60px]">
              Apply now
            </h1>

            <div className=" mt-8 lg:mt-0 px-gutter lg:px-[60px]">
              {" "}
              <CareerForm
                formName={formName}
                submitted={submitted}
                setSubmitted={setSubmitted}
              />
            </div>
          </motion.div>
        )}
        {submitted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="bg-orange-light px-gutter lg:px-0 py-[60px] "
          >
            <h1 className="text-orange blockH4 px-gutter lg:px-[60px] pb-10">
              Thank you! Your application has been received and we will be in
              touch shortly.
            </h1>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
